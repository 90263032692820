import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `
const [sendMessage, {data, error}] = useSendMessageMutation();

sendMessage({
  variables: {
    text: 'foo',
    authorId: '1'
  }
});

if (error) {
  throw error;
}

console.log(data?.sendMessage.id);
`;

export const MutationHookSnippet = () => {
  return (<Highlighter language="tsx" wrapLines={true}>
    {code}
  </Highlighter>);
};
