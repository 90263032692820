import React from 'react'
import {Image} from 'mdx-deck'

export const Profile = ({image, name, occupation, email, twitter, textAlign}) => {
    return (
        <Image src={image}>
            <div style={{width: "100vw", padding: "0 2rem", display: "flex", flexDirection: "column", alignItems: `${textAlign === 'right' ? 'flex-end' : 'flex-start'}`}}>
                <h2>{name}</h2>
                <div style={{marginBottom: "5rem"}}>{occupation}</div>
                <div style={{marginBottom: "1rem"}}>{email}</div>
                <div>{twitter}</div>
            </div>
        </Image>
    );
}
