// Custom theme based on github code highlighting

import { github } from "@code-surfer/themes";

const theme = {
    fonts: {
        body: 'Roboto, sans-serif',
        monospace: '"Roboto Mono", monospace',
    },
    colors: {
        text: 'white',
        background: '#128589',
        primary: 'white',
    },
    styles: {
        a: {
            ':visited': {
                color: 'inherit'
            },
            color: 'white',
            textDecoration: 'none'
        },
        CodeSurfer: {
            ...github.styles.CodeSurfer,
            title: {
                color: "#128589"
            },
            subtitle: {
                color: "white",
                backgroundColor: "#128589"
            },
        },
    },
}

export default theme

