
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import {Image, Steps, Invert, Notes} from 'mdx-deck'
import {
    CodeSurfer,
    CodeSurferColumns,
    Step,
} from "code-surfer";
import {github} from "@code-surfer/themes";
import suoraTheme from "./theme"
import {getLowercaseGraphQl, LowercaseGraphQl} from "./components/LowercaseGraphQl"
import {Profile} from "./components/Profile"
import {GqlCodeSurfer} from './components/GqlCodeSurfer'
import {GqlCodeSurferColumns} from './components/GqlCodeSurferColumns'
import {YarnCodegenInstallSnippet} from './components/snippets/YarnCodegenInstallSnippet'
import {CodegenYamlSnippet} from './components/snippets/CodegenYamlSnippet'
import {CodegenCommandSnippet} from './components/snippets/CodegenCommandSnippet'
import {DummyQuerySnippet} from './components/snippets/DummyQuerySnippet'
import {RunCodegenSnippet} from './components/snippets/RunCodegenSnippet'
import {QueryHookSnippet} from './components/snippets/QueryHookSnippet'
import {QueryErrorSnippet} from './components/snippets/QueryErrorSnippet'
import {MutationErrorSnippet} from './components/snippets/MutationErrorSnippet'
import {MutationHookSnippet} from './components/snippets/MutationHookSnippet'
import {DummyMutationSnippet} from './components/snippets/DummyMutationSnippet'
import {ConnectionQuerySnippet} from './components/snippets/ConnectionQuerySnippet'
import {ConnectionQueryWithArgumentsSnippet} from './components/snippets/ConnectionQueryWithArgumentsSnippet'
import {ApolloCacheSnippet} from './components/snippets/ApolloCacheSnippet'
import profileImageJan from "./images/bandfoto-jan.jpg"
import profileImageChristian from "./images/bandfoto-christian.png"
import bandfoto from "./images/bandfoto-gefaerbt3_hintergrund.jpg"
import graphPlain from "./images/graph/ohne Beschriftung.svg"
import graphLabelled from "./images/graph/mit Beschriftung.svg"
import toolGraphiQl from "./images/tooling/graphiql.png"
import toolGraphQlPlayground from "./images/tooling/gql-playground.png"
export const theme = suoraTheme;

const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`GraphQL for React applications`}</h1>
    <hr></hr>
    <Image src={bandfoto} mdxType="Image"><div style={{
        display: "flex",
        width: "100%",
        height: "100%",
        padding: "2rem",
        justifyContent: "flex-end",
        alignItems: "flex-end"
      }}>suora.com</div></Image>
    <hr></hr>
    <Profile image={profileImageChristian} name={"Hans-Christian Otto"} email={"c.otto@suora.com"} occupation={"Consultant with Suora"} twitter={"@muhdiekuh"} textAlign="right" mdxType="Profile" />
    <hr></hr>
    <Profile image={profileImageJan} name={"Jan Krausenbaum"} email={"j.krausenbaum@suora.com"} occupation={"Consultant with Suora"} twitter={"@jankrausenbaum"} mdxType="Profile" />
    <hr></hr>
    <Invert mdxType="Invert">
      <h1>{`Now what's this GraphQL thing?`}</h1>
    </Invert>
    <hr></hr>
    <h1>{`Now what's this GraphQL thing?`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Query and manipulation language`}</li>
        <li parentName="ul">{`Often (but not only) used via HTTP`}</li>
        <li parentName="ul">{`Can be seen as an alternative to REST`}</li>
        <li parentName="ul">{`Violates REST`}</li>
        <li parentName="ul">{`It's strictly typed`}</li>
        <li parentName="ul">{`It has something to do with graphs`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <Invert mdxType="Invert">
      <img src={graphPlain} style={{
        height: "75vh",
        width: "auto",
        display: "flex"
      }} />
    </Invert>
    <hr></hr>
    <Invert mdxType="Invert">
      <img src={graphLabelled} style={{
        height: "75vh",
        width: "auto",
        display: "flex"
      }} />
    </Invert>
    <hr></hr>
    <h1>{`Queries`}</h1>
    <hr></hr>
    <GqlCodeSurferColumns themes={[github, github]} mdxType="GqlCodeSurferColumns">
      <Step mdxType="Step">
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Client-side operation\"",
            "title": "\"Client-side",
            "operation\"": true
          }}>{`query AllMessages {
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-json",
            "metastring": "title=\"Server response\"",
            "title": "\"Server",
            "response\"": true
          }}>{`{
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Client-side operation\"",
            "title": "\"Client-side",
            "operation\"": true
          }}>{`query AllMessages {
  messages {
    id
    text
  }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-json",
            "metastring": "title=\"Server response\"",
            "title": "\"Server",
            "response\"": true
          }}>{`{
  "data": {
    "messages": [
      {
        "id": 1,
        "text": "Hi there!",
      },
      {
        "id": 2,
        "text": "How's it going?",
      },
    ]
  }
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Client-side operation\"",
            "title": "\"Client-side",
            "operation\"": true
          }}>{`query AllMessages {
  messages {
    id
    text
    author {
      id
      firstName
      lastName
    }
  }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-json",
            "metastring": "title=\"Server response\"",
            "title": "\"Server",
            "response\"": true
          }}>{`{
  "data": {
    "messages": [
      {
        "id": 1,
        "text": "Hi there!",
        "author": {
          "id": 1,
          "firstName": "Jan",
          "lastName": "Krausenbaum"
        }
      },
      {
        "id": 2,
        "text": "How's it going?",
        "author": {
          "id": 2,
          "firstName": "Florian",
          "lastName": "Sowade"
        }
      },
    ]
  }
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "1:17 title=\"Client-side operation\"",
            "1:17": true,
            "title": "\"Client-side",
            "operation\"": true
          }}>{`query AllMessages {
  messages {
    id
    text
    author {
      id
      firstName
      lastName
    }
  }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-json",
            "metastring": "1:24 title=\"Server response\"",
            "1:24": true,
            "title": "\"Server",
            "response\"": true
          }}>{`{
  "data": {
    "messages": [
      {
        "id": 1,
        "text": "Hi there!",
        "author": {
          "id": 1,
          "firstName": "Jan",
          "lastName": "Krausenbaum"
        }
      },
      {
        "id": 2,
        "text": "How's it going?",
        "author": {
          "id": 2,
          "firstName": "Florian",
          "lastName": "Sowade"
        }
      },
    ]
  }
}
`}</code></pre>
      </Step>
    </GqlCodeSurferColumns>
    <hr></hr>
    <h1>{`Querying data is great, but how do we change things? 🤔`}</h1>
    <hr></hr>
    <h1>{`Introducing mutations`}</h1>
    <hr></hr>
    <GqlCodeSurferColumns themes={[github, github]} mdxType="GqlCodeSurferColumns">
      <Step mdxType="Step">
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat"
          }}>{`mutation SendMessage {
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat"
          }}>{`mutation SendMessage($text: String!, $authorId: ID!) {
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat"
          }}>{`mutation SendMessage($text: String!, $authorId: ID!) {
  createMessage(text: $text, authorId: $authorId) {
    id
    text
    createdAt
  }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "data": {
    "createMessage": {
      id: 3,
      text: "Trying out some mutations",
      createdAt: "Some seconds ago"
    }
  }
}
`}</code></pre>
      </Step>
    </GqlCodeSurferColumns>
    <hr></hr>
    <h1>{`GraphQL and React using Apollo`}</h1>
    <hr></hr>
    <h1>{`Apollo`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Implementation of GraphQL`}</li>
        <li parentName="ul">{`Client and server platform for GraphQL-Operations`}</li>
        <li parentName="ul">{`Connection between client applications and back-end services`}</li>
        <li parentName="ul">{`React client`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`Querying data with Apollo using React`}</h1>
    <DummyQuerySnippet mdxType="DummyQuerySnippet" />
    <hr></hr>
    <h1>{`Executing queries`}</h1>
    <QueryHookSnippet mdxType="QueryHookSnippet" />
    <hr></hr>
    <h1>{`Mutations with Apollo`}</h1>
    <DummyMutationSnippet mdxType="DummyMutationSnippet" />
    <hr></hr>
    <h1>{`Executing mutations`}</h1>
    <MutationHookSnippet mdxType="MutationHookSnippet" />
    <hr></hr>
    <h1>{`Let's look at a live example 🛠💪`}</h1>
    <hr></hr>
    <h1>{`Recap: What did we just see?`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Query and mutation definitions`}</li>
        <li parentName="ul">{`Executing queries and mutations using generated hooks`}</li>
        <li parentName="ul">{`Fine-grained control over data delivered`}</li>
        <li parentName="ul">{`Type-safety for queries and data payloads`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`But how does this actually work?`}</h1>
    <hr></hr>
    <h1>{`GraphQL Schema`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Data types - scalars and nodes`}</li>
        <li parentName="ul">{`Connections between nodes`}</li>
        <li parentName="ul">{`Queries and mutations`}</li>
      </ul>
    </Steps>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`Social media origin`}</li>
        <li parentName="ul">{`Strong typing`}</li>
        <li parentName="ul">{`Scalar and complex types`}</li>
        <li parentName="ul">{`Most self-defined types will be complex types`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <GqlCodeSurferColumns themes={[suoraTheme, github]} title="A basic GraphQL schema" mdxType="GqlCodeSurferColumns">
      <Step mdxType="Step">
        <ul>
          <li parentName="ul">{`A basic node with some properties`}</li>
        </ul>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"GraphQL schema\"",
            "title": "\"GraphQL",
            "schema\"": true
          }}>{`type Message {
  id: ID
  text: String
  createdAt: String
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <ul>
          <li parentName="ul">{`A basic node with some properties`}</li>
          <li parentName="ul">{`Properties are nullable by default`}</li>
        </ul>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"GraphQL schema\"",
            "title": "\"GraphQL",
            "schema\"": true
          }}>{`type Message {
  id: ID!
  text: String!
  createdAt: String!
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <ul>
          <li parentName="ul">{`A basic node with some properties`}</li>
          <li parentName="ul">{`Nullable by default`}</li>
          <li parentName="ul">{`We can define custom scalar types`}</li>
        </ul>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"GraphQL schema\"",
            "title": "\"GraphQL",
            "schema\"": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  createdAt: Date!
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <p>{`Let's add another complex type`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"GraphQL schema\"",
            "title": "\"GraphQL",
            "schema\"": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <p>{`Node types can be used as properties in other types`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"GraphQL schema\"",
            "title": "\"GraphQL",
            "schema\"": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  author: Person!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <p>{`Accessing data: Let's add a Query`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"GraphQL schema\"",
            "title": "\"GraphQL",
            "schema\"": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  author: Person!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
}

type Query {
  messages: [Message!]!
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <p>{`Changing data: mutations`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"GraphQL schema\"",
            "title": "\"GraphQL",
            "schema\"": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  author: Person!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
}

type Query {
  messages: [Message!]!
}

type Mutation {
  createMessage(text: String!, authorId: ID!): Message!
}
`}</code></pre>
      </Step>
    </GqlCodeSurferColumns>
    <hr></hr>
    <h1>{`Schema semantics`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Contract about structure of graph`}</li>
        <li parentName="ul">{`Shared between client and server`}</li>
        <li parentName="ul">{`Describes the domain model`}</li>
        <li parentName="ul">{`Describes how to query and change data`}</li>
        <li parentName="ul">{`Enables code generation and type safety`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`We've got the basics covered`}</h1>
    <hr></hr>
    <h1>{`Let's look at some details`}</h1>
    <hr></hr>
    <h1>{`Apollo Cache`}</h1>
    <ApolloCacheSnippet mdxType="ApolloCacheSnippet" />
    <ul>
      <li parentName="ul">{`Apollo comes with an `}<inlineCode parentName="li">{`InMemoryCache`}</inlineCode>{` out of the box`}</li>
      <li parentName="ul">{`Other options can be implemented`}</li>
      <li parentName="ul">{`Apollo uses the cache for "smart" updates`}</li>
      <li parentName="ul">{`How the cache is used can be controlled with fetch policies`}</li>
      <li parentName="ul">{`Local state of data can be used for "optimistic updates"`}</li>
    </ul>
    <hr></hr>
    <h1>{`What about errors?`}</h1>
    <hr></hr>
    <h1>{`Handling errors in queries`}</h1>
    <QueryErrorSnippet mdxType="QueryErrorSnippet" />
    <hr></hr>
    <h1>{`Handling errors in mutations`}</h1>
    <MutationErrorSnippet mdxType="MutationErrorSnippet" />
    <hr></hr>
    <h1>{`Subscriptions`}</h1>
    <hr></hr>
    <h1>{`Testing`}</h1>
    <hr></hr>
    <h1>{`Let's look at how to do more complex operations`}</h1>
    <hr></hr>
    <GqlCodeSurferColumns mdxType="GqlCodeSurferColumns">
      <Step mdxType="Step">
        <p>{`This is our basic schema from before`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Modelling relations\"",
            "title": "\"Modelling",
            "relations\"": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  author: Person!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
}

type Query {
  messages: [Message!]!
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <ul>
          <li parentName="ul">{`We now add something called a `}<inlineCode parentName="li">{`Connection`}</inlineCode></li>
        </ul>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Modelling relations\" 1:32",
            "title": "\"Modelling",
            "relations\"": true,
            "1:32": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  author: Person!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
  messagesConnection: PersonMessagesConnection
}

type Query {
  messages: [Message!]!
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <ul>
          <li parentName="ul">{`We now add something called a `}<inlineCode parentName="li">{`Connection`}</inlineCode></li>
          <li parentName="ul">{`Simply put, Connections represent a set of edges between nodes`}</li>
        </ul>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Modelling relations\" 1:32",
            "title": "\"Modelling",
            "relations\"": true,
            "1:32": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  author: Person!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
  messagesConnection: PersonMessagesConnection
}

type PersonMessagesConnection {
  edges: [PersonMessagesEdge!]!
}

type Query {
  messages: [Message!]!
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <ul>
          <li parentName="ul">{`We now add something called a `}<inlineCode parentName="li">{`Connection`}</inlineCode></li>
          <li parentName="ul">{`Simply put, Connections represent a set of edges between nodes`}</li>
          <li parentName="ul">{`Every edge points to another node`}</li>
        </ul>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Modelling relations\" 1:32",
            "title": "\"Modelling",
            "relations\"": true,
            "1:32": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  author: Person!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
  messagesConnection: PersonMessagesConnection
}

type PersonMessagesConnection {
  edges: [PersonMessagesEdge!]!
}

type PersonMessagesEdge {
  node: Message!
}

type Query {
  messages: [Message!]!
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <ul>
          <li parentName="ul">{`We now add something called a `}<inlineCode parentName="li">{`Connection`}</inlineCode></li>
          <li parentName="ul">{`Simply put, Connections represent a set of edges between nodes`}</li>
          <li parentName="ul">{`Every edge points to another node`}</li>
          <li parentName="ul">{`Edges can have properties themselves`}</li>
        </ul>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Modelling relations\" 1:32",
            "title": "\"Modelling",
            "relations\"": true,
            "1:32": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  author: Person!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
  messagesConnection: PersonMessagesConnection
}

type PersonMessagesConnection {
  edges: [PersonMessagesEdge!]!
}

type PersonMessagesEdge {
  node: Message!
  authoredAt: Date!
}

type Query {
  messages: [Message!]!
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <ul>
          <li parentName="ul">{`We now add something called a Connection`}</li>
          <li parentName="ul">{`Simply put, Connections represent a set of edges between nodes`}</li>
          <li parentName="ul">{`Every edge points to another node`}</li>
          <li parentName="ul">{`Edges can have properties themselves`}</li>
        </ul>
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Modelling relations\" 1:32",
            "title": "\"Modelling",
            "relations\"": true,
            "1:32": true
          }}>{`scalar Date

type Message {
  id: ID!
  text: String!
  author: Person!
  createdAt: Date!
}

type Person {
  id: ID!
  firstName: String!
  lastName: String!
  email: String
  messagesConnection: PersonMessagesConnection
}

type PersonMessagesConnection {
  edges: [PersonMessagesEdge!]!
}

type PersonMessagesEdge {
  node: Message!
  authoredAt: Date!
}

type Query {
  messages: [Message!]!
}
`}</code></pre>
      </Step>
    </GqlCodeSurferColumns>
    <hr></hr>
    <h1>{`A Query using a Connection`}</h1>
    <ConnectionQuerySnippet mdxType="ConnectionQuerySnippet" />
    <hr></hr>
    <GqlCodeSurferColumns mdxType="GqlCodeSurferColumns">
      <Step mdxType="Step">
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Query\"",
            "title": "\"Query\""
          }}>{`query AllPeopleWithMessages() {
  allPeople() {
    id
    firstName
    lastName
  }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-json",
            "metastring": "title=\"Result\"",
            "title": "\"Result\""
          }}>{`{
  "data": {
    "allPeople": [
      {
        "id": "1",
        "firstName": "Florian",
        "lastName": "Sowade"
      }
      ...
    ]
  }
}
`}</code></pre>
      </Step>
      <Step mdxType="Step">
        <pre><code parentName="pre" {...{
            "className": "language-gql-gatsby-compat",
            "metastring": "title=\"Query\"",
            "title": "\"Query\""
          }}>{`query AllPeopleWithMessages() {
  allPeople() {
    id
    firstName
    lastName
    messagesConnection {
      edges {
        node {
          id
          text
        }
      }
    }
  }
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-json",
            "metastring": "title=\"Result\"",
            "title": "\"Result\""
          }}>{`{
  "data": {
    "allPeople": [
      {
        "id": "1",
        "firstName": "Florian",
        "lastName": "Sowade"
        "messagesConnection": {
          "edges": [
            "node": {
              "id": "1",
              "text": "Message 1"
            },
            "node": {
              "id": "2",
              "text": "Message 2"
            },
            ...
          ]
        }
      },
      ...
    ]
  }
}
`}</code></pre>
      </Step>
    </GqlCodeSurferColumns>
    <hr></hr>
    <h1>{`Tooling`}</h1>
    <div style={{
      display: "flex",
      width: "100%",
      height: "50%",
      justifyContent: "center"
    }}>
      <Image src={toolGraphiQl} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: "50px",
        width: "800px",
        backgroundPosition: "0% 0%"
      }} mdxType="Image" />
      <Image src={toolGraphQlPlayground} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: "50px",
        width: "800px",
        backgroundPosition: "0% 0%"
      }} mdxType="Image" />
    </div>
    <hr></hr>
    <h1>{`When to use GraphQL vs. REST`}</h1>
    <p>{`As always: It depends`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Build your own queries`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fetch only what you need`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Strongly typed and documented`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Can avoid waterfall effect`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Things like file uploads, streaming and batch mutations can be tricky`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Request caching is harder`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Harder to optimize server-side`}</p>
      </li>
    </ul>
    <hr></hr>
    <h1>{`Schema changes and TypeScript`}</h1>
    <hr></hr>
    <h1>{`Thank you!`}</h1>
    <p>{`The slides are online at https://react-`}<LowercaseGraphQl mdxType="LowercaseGraphQl" />{`.suora.training`}</p>
    <p>{`The demo app can be found at `}<a parentName="p" {...{
        "href": "https://git.io/JOgK2"
      }}>{`https://git.io/JOgK2`}</a></p>
    <h2>{`Get in touch after the conference:`}</h2>
    <ul>
      <li parentName="ul">{`@jankrausenbaum — j.krausenbaum`}{`@`}{`suora.com`}</li>
      <li parentName="ul">{`@muhdiekuh — c.otto`}{`@`}{`suora.com`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;