import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `import { gql } from "@apollo/client";

gql\`
  query AllMessages {
    messages {
      id
    }
  }
\`;`;

export const DummyQuerySnippet = () => {
  return (<Highlighter language="tsx" wrapLines={true}>
    {code}
  </Highlighter>);
};
