import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `import { gql } from "@apollo/client";

gql\`
  mutation SendMessage($text: String!, $authorId: ID!) {
    createMessage(text: $text, authorId: $authorId) {
      id
    }
  }
\`;`;

export const DummyMutationSnippet = () => {
  return (<Highlighter language="tsx" wrapLines={true}>
    {code}
  </Highlighter>);
};
