import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `
const [sendMessageToGraphQl, {error}] =
  useSendMessageMutation();

if (error) {
  // for GraphQL errors
  console.log(error.graphQLErrors);

  // for network errors
  console.log(error.networkError);
}
`;

export const MutationErrorSnippet = () => {
    return (<Highlighter language="tsx" wrapLines={true}>
        {code}
    </Highlighter>);
};
