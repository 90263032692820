import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `query AllPeopleWithMessages() {
  allPeople() {
    id
    firstName
    lastName
    messagesConnection {
      edges {
        node {
          id
          text
        }
      }
    }
  }
}`;

export const ConnectionQuerySnippet = () => {
  return (<Highlighter language="graphql" wrapLines={true}>
    {code}
  </Highlighter>);
};
