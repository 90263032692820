import React from "react";
import { Prism as Highlighter } from "react-syntax-highlighter";

const code = `
const {data, error} = useAllMessagesQuery();

if (error) {
  // for GraphQL errors
  console.log(error.graphQLErrors);

  // for network errors
  console.log(error.networkError);
}
`;

export const QueryErrorSnippet = () => {
  return (<Highlighter language="tsx" wrapLines={true}>
    {code}
  </Highlighter>);
};
